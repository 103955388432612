.picker-width {
    width: 180% !important;
}
 @media only screen and (max-width:414px){
    .picker-width {
        width: 133% !important;
    }
}
@media only screen and (max-width:820px){
    .picker-width {
        width: 100% !important;
    }
}
@media only screen and (max-width:1240px){
    .picker-width {
        width: 110% !important;
    }
}

.tjp-title {
    color: #0A043B;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
}

.creating-para {
    color: #0A043B;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    font-weight: 700;
    text-decoration: none;
}