.toggle-switch input {
    display: none;
}

.toggle-switch .slider {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 23px;
    background-color: #ccc;
    border-radius: 34px;
    transition: background-color 0.3s;
}

.toggle-switch .slider:before {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggle-switch input:checked+.slider {
    background-color: green;
}

.toggle-switch input:checked+.slider:before {
    transform: translateX(26px);
}

.toggle-switch {
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
}

.toggle-switch input:focus+.slider {
    box-shadow: 0 0 1px green;
}

.toggle-switch input:focus+.slider:before {
    box-shadow: 0 0 1px green;
}