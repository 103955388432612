.i-tooltip {
	position: absolute;
	display: inline-block;
	background-color: #4AAFB2;
	color: #fff;
	padding: 5px 10px;
	border-radius: 5px;
	white-space: nowrap;
	font-size: 12px;
	margin-top: 40px;
	margin-left: -60px;
	transform: translateY(-50%);
}

.i-icon-tooltip {
	border-radius: 50%;
	border: 1px solid #66615b;
	cursor: pointer;
	height: 15px;
	width: 15px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}