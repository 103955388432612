/*
============================================================================================
* File Name: main.scss
* This is the main scss file which contains all scss modules and converts into .css 
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Poppins',sans-serif; }

html {
  height: 100vh;
  font-size: 62.5%;
  background-color: #f0f0f0; }

body {
  color: #66615b;
  font-size: 14px;
  background-color: transparent; }

.app-container {
  width: 100%; }
  .app-container .main-container {
    width: 100%;
    min-height: 80vh; }
    .app-container .main-container .main-content {
      margin: 20px 20px 20px 100px;
      padding: 20px;
      background-color: #FFFFFF; }

@media (max-width: 768px) {
  .app-container .main-container .main-content {
    margin: 50px 10px;
    padding: 10px;
    background-color: #FFFFFF; } }

.text-muted {
  color: #dddddd; }

.text-primary,
.text-primary:hover {
  color: #51cbce !important; }

.text-info,
.text-info:hover {
  color: #51bcda !important; }

.text-success,
.text-success:hover {
  color: #4a916a !important;
  background-color: #e1f5ea;
  padding: 5px; }

.text-warning,
.text-warning:hover {
  color: #fab21f !important;
  background-color: #feedcb;
  padding: 5px; }

.text-danger,
.text-danger:hover {
  color: #f5593d !important; }

.text-danger-bg,
.text-danger-bg:hover {
  color: #f5593d !important;
  background-color: #ffdddd;
  padding: 5px; }

mark {
  background-color: lightyellow; }

.glyphicon {
  line-height: 1; }

.heart {
  color: #eb5e28;
  animation: heathing 1s ease infinite; }

.brand-button {
  background: #0A043B;
  color: #ffffff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px; }

.list-table-div {
  background-color: white; }

th {
  color: #999998; }

tr {
  height: 10px; }

.icon-td {
  width: 3%;
  padding: 3px; }

.list-table-icon {
  font-size: 22px !important;
  font-weight: bold;
  border-radius: 50%;
  color: #0A043B; }

.fa-caret-down, .fa-caret-up {
  padding-left: 5px; }

@media (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap; } }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.rotate {
  animation: rotation 2s infinite linear; }

.not-active {
  pointer-events: none;
  cursor: default;
  opacity: 0;
  margin-left: -20%; }

.list-wrapper {
  padding-bottom: 50px !important; }

input, tr, td, p, div {
  text-transform: none; }

input {
  text-transform: none; }

.app-outer-div {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh; }

::-webkit-input-placeholder {
  font-style: italic; }

:-moz-placeholder {
  font-style: italic; }

::-moz-placeholder {
  font-style: italic; }

:-ms-input-placeholder {
  font-style: italic; }

.icon {
  font-style: normal; }

.back-button {
  font-weight: bolder; }

.pl1 {
  padding-left: 30px !important; }

.pl2 {
  padding-left: 60px !important; }

.MuiIconButton-root {
  font-size: 22px !important;
  color: #0A043B !important; }

.MuiToolbar-root {
  align-items: center !important; }

.MuiTablePagination-root {
  font-size: 12px !important; }

MuiSelect-icon {
  top: calc(50% - 8px) !important; }

.MTablePaginationInner-root-14 {
  color: black !important; }

element.style {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: inherit;
  text-align: center;
  align-self: center; }

.MuiTypography-caption {
  color: red !important;
  font-size: 10px !important; }

.MuiInputBase-input {
  font-size: 12px !important; }

.MuiTablePagination-toolbar {
  padding-top: 1% !important; }

.MuiTablePagination-root:last-child {
  padding: 0;
  border-bottom: none; }

ul.breadcrumb1 {
  list-style: none;
  background-color: none !important; }

ul.breadcrumb1 li {
  display: inline;
  font-size: 14px; }

ul.breadcrumb1 li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0"; }

ul.breadcrumb1 li a {
  color: #0275d8;
  text-decoration: none; }

ul.breadcrumb1 li a:hover {
  color: #01447e;
  text-decoration: underline; }

.breadcrumb1 {
  background-color: none !important; }

.style_enter__29LyQ {
  text-transform: none !important; }

.csv-title {
  color: #0A043B;
  font-size: 16px;
  font-weight: bold; }

.csv-link-label {
  color: #0A043B;
  margin-left: 10px;
  font-size: 12px; }

.csv-link-label:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #4a916a; }

.error-msg {
  text-transform: none; }

.MuiIcon-root {
  font-size: 1.8rem !important; }

button:focus {
  outline: none !important; }

.MuiIconButton-colorInherit {
  color: white; }

.text-transform-none {
  text-transform: none !important; }

.pl-0 {
  padding-left: 0px !important; }

.live-sync-icon-and-text-container {
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 0 20px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .live-sync-icon-and-text-container i.fa-refresh.icon {
    margin: 0 10px; }
  .live-sync-icon-and-text-container .enable-icon, .live-sync-icon-and-text-container .disable-icon {
    margin: 0px; }

.infobox-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #0A043B;
  opacity: 0.85;
  padding: 12px;
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
  border-radius: 10px; }

.infobox-wrapper :hover {
  cursor: pointer; }

.inner-info {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-transform: capitalize; }

.inner-info :first-child {
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  line-height: 20px; }

.inner-info :nth-child(2) {
  margin-top: 10px;
  text-decoration: underline;
  float: right;
  margin-left: 100%;
  margin-right: 35%;
  font-size: 12;
  font-weight: 500;
  width: 70px;
  line-height: 20px; }

.underline-italic {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer; }

.data-create-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px; }

.work-flow-navbar {
  font-size: 25px;
  font-weight: 600;
  color: #4267B2;
  text-decoration: none !important; }

.program-tracker-heading {
  font-size: 14.5px;
  font-weight: 600;
  text-decoration: none !important; }

.navigation-header-anchor {
  text-decoration: none !important; }

.form-button-div {
  display: flex;
  justify-content: center;
  align-items: center; }

/*
============================================================================================
* File Name: _button.scss
* This file contains all button styles that are globally appicable
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.brand-button {
  width: 150px;
  padding: 10px;
  border: 2px solid #0A043B;
  border-radius: 5px;
  background: #0A043B;
  font-size: 16px;
  color: #fff;
  cursor: pointer; }
  .brand-button:hover {
    background: #2674c8;
    border: 2px solid #2674c8; }

.cancel_button {
  width: 150px;
  padding: 10px;
  border: 2px solid #0A043B;
  border-radius: 5px;
  background: #ffffff;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  margin-left: 2%;
  border: 1px solid grey;
  color: #000000; }
  .cancel_button:hover {
    background: #f2f2f2; }

/*
*
* Buttons
*/
.button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 14px 35px;
  font-size: 14px;
  line-height: 1.3;
  border: 2px solid;
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out; }

.button-block {
  display: block;
  width: 100%; }

.button-default,
.button-default:focus {
  color: #d6d6d6;
  background-color: #2c343b;
  border-color: #2c343b; }

.button-default:hover,
.button-default:active {
  color: #ffffff;
  background-color: #00528d;
  border-color: #00528d; }

.button-primary,
.button-primary:focus {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-primary:hover,
.button-primary:active {
  color: #ffffff;
  /* background-color: #ca5217;
	border-color: #ca5217; */
  background-color: #00528d;
  border-color: #00528d; }

.bg-primary .button-primary,
.bg-primary .button-primary:focus {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.bg-primary .button-primary:hover,
.bg-primary .button-primary:active {
  color: #ffffff;
  background-color: #ca5217;
  border-color: #ca5217; }

/* Grey Button Start */
.button-grey,
.button-grey:focus {
  color: #ffffff;
  background-color: #B8B8B9;
  border-color: #B8B8B9; }

.button-grey:hover,
.button-grey:active {
  color: #ffffff;
  /* background-color: #ca5217;
	border-color: #ca5217; */
  background-color: #ca5217;
  border-color: #ca5217; }

.bg-grey .button-grey,
.bg-grey .button-grey:focus {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.bg-grey .button-grey:hover,
.bg-grey .button-grey:active {
  color: #ffffff;
  background-color: #ca5217;
  border-color: #ca5217; }

/* Grey Button Ends */
.button-gray-100,
.button-gray-100:focus {
  color: #151515;
  background-color: #f3f4f9;
  border-color: #f3f4f9; }

.button-gray-100:hover,
.button-gray-100:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-secondary,
.button-secondary:focus {
  color: #0A043B;
  background-color: #ffffff;
  border-color: #0A043B;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  width: 155px; }

.button-secondary:hover,
.button-secondary:active {
  color: #ffffff;
  background-color: #0A043B;
  border-color: #0A043B; }

.button-blue-9,
.button-blue-9:focus {
  color: #ffffff;
  background-color: #49555e;
  border-color: #49555e; }

.button-blue-9:hover,
.button-blue-9:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-blue-9 .icon,
.button-blue-9:active .icon,
.button-blue-9:focus .icon {
  color: #0070c0; }

.button-blue-9:hover .icon {
  color: #ffffff; }

.button-default-outline,
.button-default-outline:focus {
  color: #151515;
  background-color: transparent;
  border-color: #e0e0e0; }

.button-default-outline:hover,
.button-default-outline:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.button-primary-outline,
.button-primary-outline:focus {
  color: #0070c0;
  background-color: transparent;
  border-color: #0070c0; }

.button-primary-outline:hover,
.button-primary-outline:active {
  color: #ffffff;
  background-color: #0070c0;
  border-color: #0070c0; }

.bg-primary .button-primary-outline,
.bg-primary .button-primary-outline:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff; }

.bg-primary .button-primary-outline:hover,
.bg-primary .button-primary-outline:active {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.button-gray-800-outline,
.button-gray-800-outline:focus {
  color: #151515;
  background-color: transparent;
  border-color: #151515; }

.button-gray-800-outline:hover,
.button-gray-800-outline:active {
  color: #ffffff;
  background-color: #151515;
  border-color: #151515; }

.button-ghost {
  border: 0;
  background-color: transparent; }

.button-ghost:hover {
  color: #ffffff;
  background: #0070c0; }

.button-facebook,
.button-facebook:focus {
  color: #ffffff;
  background-color: #4d70a8;
  border-color: #4d70a8; }

.button-facebook:hover,
.button-facebook:active {
  color: #ffffff;
  background-color: #6585b9;
  border-color: #6585b9; }

.button-twitter,
.button-twitter:focus {
  color: #ffffff;
  background-color: #02bcf3;
  border-color: #02bcf3; }

.button-twitter:hover,
.button-twitter:active {
  color: #ffffff;
  background-color: #21cbfd;
  border-color: #21cbfd; }

.button-google,
.button-google:focus {
  color: #ffffff;
  background-color: #e2411e;
  border-color: #e2411e; }

.button-google:hover,
.button-google:active {
  color: #ffffff;
  background-color: #e76042;
  border-color: #e76042; }

.button-primary-hold {
  margin-left: 50%;
  font-size: 12px; }

.button-primary-hold,
.button-primary-hold:focus {
  color: #0070c0;
  background-color: transparent;
  border-color: #0070c0; }

.button-primary-hold:hover,
.button-primary-hold:active {
  color: #ffffff;
  background-color: #151515;
  border-color: #151515; }

.bg-primary .button-primary-hold,
.bg-primary .button-primary-hold:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff; }

.bg-primary .button-primary-hold:hover,
.bg-primary .button-primary-hold:active {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.button-primary-reject {
  margin-left: 10px;
  font-size: 12px; }

.button-primary-reject,
.button-primary-reject:focus {
  color: #ffffff;
  background-color: red;
  border-color: red; }

.button-primary-reject:hover,
.button-primary-reject:active {
  color: #ffffff;
  background-color: red;
  border-color: #151515; }

.bg-primary .button-primary-reject,
.bg-primary .button-primary-reject:focus {
  color: #151515;
  background-color: #ffffff;
  border-color: #ffffff; }

.bg-primary .button-primary-reject:hover,
.bg-primary .button-primary-reject:active {
  color: #ffffff;
  background-color: red;
  border-color: #151515; }

.button-shadow {
  box-shadow: 0 9px 21px 0 rgba(214, 214, 214, 0.6); }

.button-shadow:hover {
  box-shadow: 0 9px 10px 0 rgba(214, 214, 214, 0.6); }

.button-shadow:focus,
.button-shadow:active {
  box-shadow: none; }

.button-primary-approved,
.button-primary-approved:focus {
  color: #ffffff;
  background-color: darkgreen;
  border-color: darkgreen; }

.button-primary-approved:hover,
.button-primary-approved:active {
  color: #ffffff;
  background-color: green;
  border-color: green; }

.button-shadow-1 {
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.18); }

.button-xs {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .1em;
  border-radius: 4px; }

.button-sm {
  font-weight: 500;
  letter-spacing: .1em;
  padding: 10px 25px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 4px; }

.button-lg {
  font-weight: 500;
  letter-spacing: .05em;
  padding: 17px 40px;
  font-size: 14px;
  line-height: 1.5; }

.button-xl {
  letter-spacing: .075em;
  padding: 16px 50px;
  font-size: 16px;
  line-height: 28px; }

@media (min-width: 992px) {
  .button-xl {
    padding: 20px 80px; } }

.button-circle {
  border-radius: 30px; }

.button-round-1 {
  border-radius: 5px; }

.button-round-2 {
  border-radius: 10px; }

.button.button-icon,
.button-icon .button-inner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle; }

.button-icon-right,
.button-icon-right .button-inner {
  flex-direction: row-reverse; }

.button-icon .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: inherit;
  font-size: 1.55em;
  line-height: 1em;
  transition: color inherit; }

.button-icon-left .icon {
  padding-right: 11px; }

.button-icon-right .icon {
  padding-left: 11px; }

.button-icon.button-xs .icon {
  padding-right: 5px; }

.button-icon.button-xs .button-icon-right .icon {
  padding-left: 5px; }

.button-icon.button-sm .icon {
  top: .05em;
  font-size: 1.2em;
  padding-right: 8px; }

.button-icon.button-sm .button-icon-right .icon {
  padding-left: 8px; }

.button.button-icon-only {
  padding: 5px; }

.btn-primary {
  border-radius: 3px;
  font-family: 'rubiklight', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 700;
  letter-spacing: .05em;
  text-transform: uppercase;
  transition: .33s; }

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  color: #ffffff;
  background: #0070c0;
  border-color: #0070c0; }

.btn-primary:hover {
  color: #ffffff;
  background: #000000;
  border-color: #000000; }

.button-group {
  display: flex; }

.button-group .button {
  margin: 0 2px;
  flex-grow: 1; }

.button-group .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.button-group .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.group .button-fixed-size {
  display: inline-flex; }

.button-fixed-size {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 5px; }

.button-fixed-size img {
  width: auto;
  height: auto;
  max-width: 90%; }

@media (min-width: 576px) {
  .button-fixed-size {
    width: 177px; } }

label.button input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/*
============================================================================================
* File Name: _login-page.scss
* This file contains all Login Page Styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.outer_login_div {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center; }
  .outer_login_div input {
    text-transform: none; }

.main_div {
  width: 365px;
  background: #ffffff;
  padding: 15px 30px 30px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15); }
  .main_div .title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #2268b3;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none; }
  .main_div .brand_logo_view {
    margin-top: 0px;
    display: flex;
    justify-content: center; }
    .main_div .brand_logo_view img {
      width: auto;
      height: 60px; }
  .main_div form {
    margin-top: 25px; }
    .main_div form .login-dropdown {
      height: 100%;
      width: 100%;
      outline: none;
      border: 1px solid lightgrey;
      border-radius: 5px;
      padding-left: 50px;
      font-size: 17px;
      transition: all 0.3s ease; }
    .main_div form .input_box {
      height: 50px;
      width: 100%;
      position: relative;
      margin-top: 15px; }
      .main_div form .input_box input {
        height: 100%;
        width: 100%;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding-left: 50px;
        font-size: 17px;
        transition: all 0.3s ease; }
        .main_div form .input_box input :focus {
          border-color: red; }
      .main_div form .input_box .icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        color: grey;
        font-size: 18px;
        font-style: normal; }
      .main_div form .input_box button {
        height: 100%;
        width: 100%;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 5px;
        font-size: 17px;
        transition: all 0.3s ease; }
    .main_div form .option_div {
      margin-top: 5px;
      display: flex;
      justify-content: space-between; }
      .main_div form .option_div .check_box {
        display: flex;
        align-items: center; }
      .main_div form .option_div span {
        margin-left: 5px;
        font-size: 16px;
        color: #333; }
      .main_div form .option_div .forget_div a {
        font-size: 16px;
        color: grey; }
    .main_div form .button button {
      padding-left: 0;
      background: #4267B2;
      color: #fff;
      border: none;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s linear;
      text-transform: uppercase; }
      .main_div form .button button:hover {
        background: #4267B2; }
    .main_div form .error_msg {
      font-size: 12px; }

.login-warning-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 5px 20px;
  text-transform: none; }
  .login-warning-msg label {
    text-align: center; }

/*
============================================================================================
* File Name: _meter-page.scss
* This file contains Add Meter Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.meter_input_class {
  height: 100%;
  margin: 15px;
  border-radius: 5px;
  border-width: 1px;
  padding: 8px;
  outline: none;
  border: 1px solid lightgrey;
  font-size: 12px;
  transition: all 0.3s ease; }

.meter-data {
  width: 88% !important;
  margin-left: 9% !important;
  background-color: #fff;
  margin-right: 7.5%; }

.meter-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px; }

.meter-add-button {
  background: #4267B2;
  color: #fff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px; }

.action-text-alignment {
  text-align: center; }

.table tr {
  cursor: pointer; }

.table .serial-no-td {
  width: 15%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.table .meter-type-td {
  width: 16%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .meter-unit-td {
  width: 6%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: none; }

.table .meter-status-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .difference-td {
  width: 10%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  text-align: center;
  font-weight: normal; }

.table .meter-amr-td {
  width: 14%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .consumer-no-td {
  width: 15%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .meter-assign-td {
  width: 8%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  padding-left: 30px; }

.table .action-td {
  width: 17%;
  font-size: 25px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  padding-left: 18px; }

.table .action-div {
  width: 100%; }
  .table .action-div :nth-child(2) {
    margin-left: 15px;
    font-size: 32px; }
  .table .action-div .fa-edit {
    font-size: 22px;
    color: #0A043B; }
  .table .action-div .fa-trash {
    font-size: 22px; }
  .table .action-div .fa-eye {
    font-size: 22px;
    margin-left: 10px; }

.iconRedColor {
  color: red; }

.iconGreenColor {
  color: green; }

.align {
  text-align: center; }

@media (max-width: 1024px) {
  .meter-data {
    width: 100% !important; }
  .table .consumer-no-td {
    width: 12%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important;
    font-weight: normal; }
  .table .action-td {
    width: 25%;
    font-size: 25px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important; } }

@media (max-width: 414px) {
  .meter-data {
    width: 100% !important; }
  .align-icon {
    margin-left: 0px !important; } }

/*
============================================================================================
* File Name: _add-meter-page.scss
* This file contains Add Meter Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
:root {
  --blue: $BRAND_COLOR
; }

.add-meter-main-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; }
  .add-meter-main-view .create-meter-div {
    background-color: #fff;
    width: 70%;
    border: 1px;
    margin-top: 3.5%; }
    .add-meter-main-view .create-meter-div label {
      display: inline-block; }
    .add-meter-main-view .create-meter-div .meter-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px; }
      .add-meter-main-view .create-meter-div .meter-icon-wrapper h4 {
        color: #0A043B;
        font-weight: 700;
        letter-spacing: 1px; }
    .add-meter-main-view .create-meter-div .list-table-icon {
      font-size: 32px;
      font-weight: bold;
      border-radius: 50%;
      color: #0A043B; }
    .add-meter-main-view .create-meter-div form {
      padding: 0px 50px;
      margin-bottom: 20px; }
    .add-meter-main-view .create-meter-div .form-label {
      color: #999998;
      font-family: avenir;
      font-size: 14px;
      margin-bottom: 0;
      margin-left: 8px; }
    .add-meter-main-view .create-meter-div .form-control {
      background-color: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 4px;
      color: #66615b;
      font-size: 14px;
      transition: background-color 0.3s ease 0s;
      padding-right: 0 !important;
      padding: 7px 12px;
      height: 40px;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .add-meter-main-view .create-meter-div .form-control:focus {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 !important; }
    .add-meter-main-view .create-meter-div .add-meter-input {
      text-align: left;
      color: #999998;
      border-radius: 8px;
      font-weight: 600;
      width: 100%;
      height: 50px;
      text-transform: capitalize; }
      .add-meter-main-view .create-meter-div .add-meter-input .email-small {
        text-transform: lowercase; }
    .add-meter-main-view .create-meter-div .add-meter-input:focus {
      color: black; }
    .add-meter-main-view .create-meter-div input.placeholderColor::placeholder {
      color: #999998;
      font-weight: normal; }
    .add-meter-main-view .create-meter-div textarea.placeholderColor::placeholder {
      color: #999998;
      font-weight: normal; }
    .add-meter-main-view .create-meter-div .error-msg {
      padding-left: 1.2%;
      font-size: 12px; }
    .add-meter-main-view .create-meter-div .input-label-group {
      margin-bottom: 20px; }
  .add-meter-main-view .meter-tab--selected {
    border-bottom: 1px solid #0A043B; }
  .add-meter-main-view .react-tabs__tab--selected {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0A043B !important; }
  .add-meter-main-view .react-tabs__tab-list {
    border-bottom: none;
    margin: 0 0 0px;
    padding: 0; }
  .add-meter-main-view .react-tabs__tab {
    border: none;
    padding: 0px 12px;
    height: 60px; }
  .add-meter-main-view .react-tabs__tab:focus:after {
    height: 0px; }
  .add-meter-main-view .meter-tab-panel--selected {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%; }
  .add-meter-main-view .set-rtc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px; }

@media (max-width: 414px) {
  .add-meter-main-view .create-meter-div {
    width: 100%; } }

@media (max-width: 1112px) {
  .cancel_button {
    width: 135px; } }

.switch-div {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

/*
============================================================================================
* File Name: _navigation-bar.scss
* This file contains all Navigation Bar Styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.top-navigation-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 9999; }
  .top-navigation-bar-container .top-navigation-bar {
    width: 100%;
    padding: 8px;
    box-shadow: 0 0 10px 1px rgba(100, 100, 100, 0.2);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .top-navigation-bar-container .top-navigation-bar label {
      margin: 0;
      font-weight: normal; }
    .top-navigation-bar-container .top-navigation-bar .top-navigation-bar-icon-container {
      padding: 0 10px;
      display: flex;
      align-items: center; }
      .top-navigation-bar-container .top-navigation-bar .top-navigation-bar-icon-container .top-navigation-bar-icon {
        font-size: 22px;
        color: #0A043B; }
    .top-navigation-bar-container .top-navigation-bar .logo-container {
      margin-left: 6vw;
      padding-left: 10px; }
      .top-navigation-bar-container .top-navigation-bar .logo-container a img {
        width: 160px; }
    .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container {
      margin-right: 6vw;
      display: flex;
      align-items: center; }
      .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .site-select-dropdown-container {
        margin-right: 100px;
        display: flex;
        align-items: center; }
      .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .user-info-container {
        display: flex;
        align-items: center; }
        .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .user-info-container .top-navigation-bar-icon-container {
          cursor: pointer; }

@media (max-width: 768px) {
  .top-navigation-bar-container .top-navigation-bar .logo-container {
    margin-left: 4vw; }
    .top-navigation-bar-container .top-navigation-bar .logo-container a img {
      width: 120px; }
  .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container {
    margin-right: 3vw; }
    .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .site-select-dropdown-container {
      margin-right: 1vw; }
    .top-navigation-bar-container .top-navigation-bar .site-select-dropdown-and-user-info-container .user-info-container {
      display: none; } }

/*
============================================================================================
* File Name: _user-page.scss
* This file contains User Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.user-button-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px; }

.list-table-div .center-heading {
  text-align: center; }

.list-table th {
  font-weight: bolder;
  font-size: 15px; }

.role-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 7.5%;
  margin-right: 7.5%;
  width: 85%;
  padding: 0px 20px; }
  .role-div .roles-label {
    font-size: 16px;
    font-weight: 900;
    text-decoration: underline;
    cursor: pointer;
    color: #0A043B; }

.user-list-table tr {
  cursor: pointer; }

.user-list-table .name-td {
  width: 18%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.user-list-table .email-td {
  width: 20%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: none; }

.user-list-table .phone-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.user-list-table .username-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  text-transform: none; }

.user-list-table .site-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.user-list-table .role-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.user-list-table .action-td {
  width: 15%;
  font-size: 22px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1; }

.user-list-table .action-div {
  width: 100%; }
  .user-list-table .action-div :nth-child(2) {
    margin-left: 15px;
    font-size: 32px; }
  .user-list-table .action-div .fa-edit {
    font-size: 22px !important;
    color: #0A043B !important; }
  .user-list-table .action-div .fa-trash {
    font-size: 22px; }

.MuiTypography-h6 {
  display: none; }

/*
============================================================================================
* File Name: _add-user-page.scss
* This file contains Add User Page styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.add-user-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; }

.create-user-div {
  background-color: #fff;
  width: 70%;
  border: 1px;
  margin-top: 3.5%; }
  .create-user-div label {
    display: inline-block; }
  .create-user-div .user-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px; }
    .create-user-div .user-icon-wrapper h4 {
      color: #0A043B;
      font-weight: 700;
      letter-spacing: 1px; }
  .create-user-div .list-table-icon {
    font-size: 32px;
    font-weight: bold;
    border-radius: 50%;
    color: #0A043B; }
  .create-user-div form {
    padding: 0px 50px;
    margin-bottom: 20px; }
  .create-user-div .form-label {
    color: #999998;
    font-family: avenir;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 8px; }
  .create-user-div .form-control {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    transition: background-color 0.3s ease 0s;
    padding-right: 0 !important;
    padding: 7px 12px;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .create-user-div .form-control:focus {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
  .create-user-div .add-user-input {
    text-align: left;
    color: #999998;
    border-radius: 8px;
    font-weight: 600;
    width: 100%;
    height: 50px;
    text-transform: capitalize; }
    .create-user-div .add-user-input .email-small {
      text-transform: lowercase; }
  .create-user-div .add-user-input:focus {
    color: black; }
  .create-user-div input.placeholderColor::placeholder {
    color: #999998;
    font-weight: normal; }
  .create-user-div textarea.placeholderColor::placeholder {
    color: #999998;
    font-weight: normal; }
  .create-user-div .error-msg {
    padding-left: 1.2%;
    font-size: 12px; }
  .create-user-div .input-label-group {
    margin-bottom: 20px; }

.text-left {
  text-align: left !important; }

.dummy_row {
  height: 20px; }

.reset_button {
  width: 55%;
  margin-top: 14%; }

.mytextstyle {
  background-color: #0A043B;
  color: white;
  font-size: 14px;
  box-sizing: border-box; }

.rowdata {
  margin-left: 2%;
  padding-bottom: 2%;
  width: 21%; }

.reset-button {
  background: #0A043B;
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 13px 10px 13px 10px;
  border-radius: 5px;
  margin-top: 13%; }

.cancel_reset_btn {
  width: 100px;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background: #ffffff;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  border: 1px solid grey;
  color: #000000;
  margin-left: -58px;
  margin-top: 70%; }

.cancel_reset_btn:hover {
  background: #f2f2f2; }

@media (max-width: 1024px) {
  .col-md-2 {
    flex: 0 0 23%;
    max-width: 100%; }
  .col-md-3 {
    flex: 0 0 35%;
    max-width: 100%; }
  .reset-button {
    margin-top: 0%;
    margin-left: 0%; }
  .cancel_reset_btn {
    margin-left: -58px;
    margin-top: 6%; }
  .cancel_button {
    width: 105px;
    padding: 7px; } }

@media (max-width: 414px) {
  .col-md-2 {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%; }
  .create-user-div form {
    padding: 0px 35px;
    width: 92%; }
  .create-user-div {
    width: 100%; }
  .rowdata {
    margin-left: 5%;
    width: 90%; }
  .ml-5 {
    margin-left: -1px !important; }
  .reset-button {
    margin-top: 0%;
    margin-left: 0%; }
  .cancel_reset_btn {
    width: 149px;
    margin-left: 0px;
    margin-top: 0%;
    padding: 5px; }
  .cancel_button {
    width: 149px;
    padding: 7px; } }

/*
============================================================================================
* File Name: _spinner.scss
* This file contains Spinner styles
* and stored in assets/css/main.css
* Author: Mahesh Londhe
============================================================================================
*/
.spinner-wrapper label {
  margin-top: 10%;
  font-size: 20px;
  color: #0A043B;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 15px; }

/*
============================================================================================
* File Name: _footer.scss
* This file contains all footer Styles
* and stored in assets/css/main.css
* Author: Sanjana
============================================================================================
*/
.main-footer {
  padding: 1rem;
  background-color: rgba(167, 168, 187, 0.493);
  text-align: center; }

/*
============================================================================================
* File Name: sidebar.scss
* This file contains all sidebar Styles
* and stored in assets/css/main.css
* Author: Sandip Wandre
============================================================================================
*/
.side-navigation-bar-container {
  position: fixed;
  z-index: 999; }
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-hamburger-button {
    width: 100%;
    border-radius: 5px;
    margin: 20px 0 5px 0;
    box-shadow: 0.5px 0.5px 6px 1px rgba(100, 100, 100, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-hamburger-button span.side-navigation-bar-icon {
      font-size: 20px; }
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner {
    border-radius: 5px;
    box-shadow: 0.5px 0.5px 6px 1px rgba(100, 100, 100, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px); }
    .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
      display: none; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu {
        padding: 0; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper {
          background-color: whitesmoke; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper span.pro-icon i.side-navigation-bar-icon {
            font-size: 20px;
            color: #0A043B; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-item-content {
          color: black; }
    .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu {
      padding: 0px; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item {
        padding: 8px 35px 7px 20px !important; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper {
          background-color: transparent; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper span.pro-icon i.side-navigation-bar-icon {
            font-size: 20px;
            color: #0A043B; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-item-content {
          color: black; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-item-content a.side-navigation-bar-menu-title {
            color: black; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item:hover {
        background-color: whitesmoke; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown {
        padding: 0;
        background-color: rgba(10, 4, 59, 0.9); }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown div ul {
          padding: 2px; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown div ul li.pro-menu-item div.pro-inner-item {
            padding: 8px 30px 8px 20px; }
            .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown div ul li.pro-menu-item div.pro-inner-item span.pro-item-content a.side-navigation-bar-menu-title {
              color: white; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.react-slidedown div ul li.pro-menu-item:hover {
            background-color: #0a043b; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.popper-element {
        padding: 0;
        background-color: transparent;
        left: 80px !important;
        transform: translate(0, 0) !important; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.popper-element div.popper-inner {
          padding: 0;
          box-shadow: 0.5px 0.5px 6px 1px rgba(100, 100, 100, 0.2);
          background-color: white; }
          .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.popper-element div.popper-inner ul {
            padding: 0px; }
            .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu ul li.pro-menu-item.pro-sub-menu div.pro-inner-list-item.popper-element div.popper-inner ul li.pro-menu-item div.pro-inner-item {
              padding: 6px 30px !important; }
    .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu {
      padding: 0; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper {
        background-color: transparent; }
        .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-icon-wrapper span.pro-icon i.side-navigation-bar-icon {
          font-size: 20px;
          color: #0A043B; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu ul li.pro-menu-item div.pro-inner-item span.pro-item-content {
        color: black; }
      .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-menu ul li.pro-menu-item:hover {
        background-color: whitesmoke; }

@media (max-width: 768px) {
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-hamburger-button {
    width: 30px;
    margin: 5px 0; }
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar {
    width: auto !important;
    height: auto !important;
    left: 0px !important; }
    .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
      display: block; }
  .side-navigation-bar-container .side-navigation-bar .side-navigation-bar-content .pro-sidebar.collapsed {
    width: 80px !important;
    left: -80px !important; } }

.side-navigation-bar-sub-menu-user .pro-inner-list-item, .side-navigation-bar-sub-menu-user .popper-element {
  margin: 0px !important;
  top: auto !important;
  margin-top: -50px !important; }

/*
============================================================================================
* File Name: _customer-page.scss
* This file contains all Customer Page styles
* and stored in assets/css/main.css
* Author: Sanjana Dolas
============================================================================================
*/
.customer_input_class {
  height: 100%;
  margin: 15px;
  border-radius: 5px;
  border-width: 1px;
  padding: 8px;
  outline: none;
  border: 1px solid lightgrey;
  font-size: 16px;
  transition: all 0.3s ease; }

.customer-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin: 0px 0px; }

.customer-add-button {
  background: #0A043B;
  color: #fff;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s linear;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  align-items: center; }

.table tr {
  cursor: pointer;
  font-size: 16px; }

.table .customer-name-td {
  width: 14%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000000;
  vertical-align: middle !important;
  text-transform: none !important; }

.table .customer-consumer-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-serial-td {
  width: 12%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-address1-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.table .customer-address2-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.table .customer-street-td {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-city-td {
  width: 7%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-pincode-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-mobile-td {
  width: 9%;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  font-weight: normal; }

.table .customer-address2-td:hover, .table .customer-address1-td:hover {
  overflow: visible;
  z-index: -1;
  color: #0A043B;
  white-space: normal; }

.table .action-td {
  width: 20%;
  font-size: 30px;
  font-weight: bold;
  margin: 0px;
  color: #808080;
  vertical-align: middle !important;
  text-transform: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  line-height: 1; }

.table .action-div {
  width: 100%; }
  .table .action-div :nth-child(2) {
    margin-left: 15px;
    font-size: 32px; }
  .table .action-div .fa-eye {
    color: #0A043B; }

.table td {
  font-weight: 700; }

@media (max-width: 1024px) {
  .list-table-div {
    width: 100% !important; }
  .table .customer-consumer-td {
    width: 10%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important; }
  .table .customer-address1-td {
    width: 8%;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #808080;
    vertical-align: middle !important;
    text-transform: none !important;
    font-weight: normal; } }

@media (max-width: 414px) {
  .list-table-div {
    width: 100% !important; }
  .align-icon {
    margin-left: 0px !important; } }

/*
============================================================================================
* File Name: _back-button.scss
* This file contains all footer Styles
* and stored in assets/css/main.css
* Author: Sanjana
============================================================================================
*/
.back-button {
  font-size: 14px;
  color: #0A043B;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 20%;
  border-radius: 4px;
  border: 5px;
  background-color: transparent; }

.back-role-page {
  margin-top: -4%;
  margin-left: -79%;
  margin-bottom: 2%; }

.create-program-outer-div {
  display: flex;
  justify-content: center;
  align-items: left;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px; }
  .create-program-outer-div .margin-bottom-20 {
    margin-bottom: 20px; }
  .create-program-outer-div .form-input {
    display: block;
    width: 100%;
    min-height: 30px;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #151515;
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    -webkit-appearance: none;
    transition: .3s ease-in-out;
    border: 1px solid #cccccc; }

.margin-top-5 {
  margin-top: 5px; }

.step-add-div {
  margin-left: 100px; }
  @media (max-width: 768px) {
    .step-add-div {
      margin-left: 0px; } }

.height-100 {
  min-height: 100px;
  height: auto; }
  .height-100 .css-13cymwt-control {
    min-height: 45px; }
  @media (max-width: 768px) {
    .height-100 {
      height: auto !important;
      width: 100%; } }

.padding-top20 {
  padding-top: 20px; }

.cursor-pointer {
  cursor: pointer; }

.left-margin-20 {
  margin-left: 20px; }

.width-auto {
  width: auto !important; }

.csv-import-button {
  display: flex;
  justify-content: center;
  align-items: center; }

.csv-import-button button:focus, .csv-import-button button:hover {
  background-color: #279f8d !important;
  border-color: #279f8d !important; }

.program-start-date-div .react-datepicker__input-container .form-control {
  height: 45px !important;
  font-size: 14px !important; }

.add-meter-main-view .create-meter-div .row .email-input input {
  text-transform: lowercase !important; }

.bottom-margin-20 {
  margin-bottom: 40px; }

.program-candidate-material-table-div .MTableHeader-header-13 {
  z-index: 0 !important; }

.spinner-loader,
.spinner-loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em; }

.spinner-loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #0A043B;
  border-right: 1.1em solid #0A043B;
  border-bottom: 1.1em solid #0A043B;
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.width-100 {
  width: 100% !important; }

.material-table-export-excel-outer-div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; }
  .material-table-export-excel-outer-div .material-table-export-excel {
    font-size: 25px;
    border: none;
    padding: 0px;
    position: absolute;
    top: 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px; }
    .material-table-export-excel-outer-div .material-table-export-excel i {
      color: #0A043B; }

.MTableToolbar-actions-8 {
  margin-right: 30px; }

.program-modal-container {
  background-color: white;
  opacity: 1; }

.program-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; }

.program-modal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute !important;
  flex-direction: column;
  width: 50vw;
  left: 25%;
  top: 0;
  right: auto;
  bottom: auto;
  background-color: white;
  opacity: 1;
  top: 17%;
  min-height: 30%;
  height: auto;
  max-height: 75%;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
  -webkit-box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
  -moz-box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82); }
  @media (max-width: 768px) {
    .program-modal {
      left: 5vw;
      width: 90vw; } }
  @media (max-width: 350px) {
    .program-modal {
      top: 5%;
      height: 500px;
      overflow-y: scroll; } }

.program-modal-inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%; }

.program-modal-row-background-color {
  background-color: #EDEDED; }

.program-modal-main-row-header {
  background-color: #ADDADB; }
  .program-modal-main-row-header th {
    color: #000000;
    font-weight: 600;
    padding-right: 10px; }

.candidate-split-plusicon-div {
  margin-top: 35px; }

.candidate-management-outer table th {
  padding-left: 20px;
  padding-right: 20px; }

.candidate-management-outer table td {
  padding-left: 20px;
  padding-right: 20px; }

.candidate-management-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.candidate-split-fields {
  padding-left: 5px;
  padding-right: 5px; }

.width-95 {
  width: 95%; }

.program-modal-div table th, .program-modal-div table td {
  padding-left: 10px;
  padding-right: 10px; }

.section-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 2px;
  background-color: #999998;
  margin-bottom: 30px; }

.bank-detail-label {
  width: 95%; }

.bank-details-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px; }

.candidate-material-table-div .MTableHeader-header-13 {
  z-index: 0 !important; }

.program-candidate-material-table-div .MTableHeader-header-13 {
  z-index: 0 !important; }

.error-modal-inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important; }

.import-error-heading {
  color: red;
  margin-bottom: 10px;
  font-weight: 700; }

.padding-right-10 {
  padding-right: 10px; }

.department-employer-table-heading {
  margin-bottom: 15px;
  font-weight: 600;
  color: #000000;
  font-size: 15px;
  text-align: center; }

.padding-top-20 {
  padding-top: 20px; }

.split-table {
  border: 1px solid lightgray; }
  .split-table input {
    border: 1px solid lightgray !important;
    width: 100%;
    padding-left: 5px; }

.padding-left-5 {
  padding-left: 5px; }

.selected-count-text {
  color: #0A043B;
  padding-left: 20px;
  text-align: center;
  font-size: 18px; }

.import-candidate-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px; }

.MuiTable-root .css-levciy-MuiTablePagination-displayedRows {
  display: none !important; }

.program-candidate-material-table-div .MuiTableHead-root th {
  z-index: 0 !important; }

.project-list-table-div .css-hyum1k-MuiToolbar-root {
  margin-right: 15px; }

.step-complete-link {
  color: #0A043B;
  cursor: pointer;
  margin-left: 10px;
  font-size: 18px !important; }

.step-complete-page-summary-outer-div {
  display: flex;
  width: 100%;
  border-color: black;
  padding-left: 2%;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #F0F0F0;
  padding: 10px;
  margin-bottom: 20px; }

.step-complete-page-summary-inner-div {
  display: flex;
  width: 50%; }
  .step-complete-page-summary-inner-div span {
    color: #000000;
    font-weight: 600;
    padding-right: 5px; }

.disaplay-none {
  display: none; }

.edit-read-button-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.width-85 {
  width: 85% !important; }

.send-notification-email-input {
  height: 36px;
  font-size: inherit; }

.text-transform-uppercase {
  text-transform: uppercase !important; }

.add-user-wrapper .email-input input {
  text-transform: lowercase !important; }

.add-user-input-height {
  height: 45px !important; }

.add-user-wrapper .css-13cymwt-control {
  height: 45px !important; }

.add-user-wrapper .css-t3ipsp-control {
  height: 45px !important;
  min-height: 45px !important; }

.password-input-div {
  display: flex;
  justify-content: flex-end; }

.eye-icon-class-for-password {
  position: absolute;
  padding-right: 20px;
  margin-top: 10px; }

.display-inline {
  display: inline !important; }

.reset-password-input-box {
  height: 40px !important;
  margin-bottom: 15px;
  margin-top: 0px !important; }

.display-flex-column-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%; }

.reset-password-modal-input {
  width: 60%;
  height: 42px !important;
  min-height: 42px !important;
  max-height: 42px !important;
  margin-top: 15px;
  padding-left: 30px; }

.reset-password-modal-eye-icon {
  margin-top: -5%;
  margin-left: 8px;
  position: absolute; }

.text-color-black {
  color: #000000 !important; }

.text-align-center {
  text-align: center; }

.padding-20px {
  padding: 20px !important; }

.user-page-outer-wrapper .css-1ygcj2i-MuiTableCell-root {
  z-index: 0; }

.tracker-candidate-name-span {
  display: flex;
  align-items: center; }
  .tracker-candidate-name-span .undo-icon-class {
    margin-left: 5px !important;
    color: #0A043B !important; }

.project-wise-dashboard-div .MuiTable-root .MuiTableHead-root .MuiTableRow-root th:first-child {
  width: 300px !important; }

.App {
  /* text-align: center; */
  margin-left: 5%; }

.App-logo {
  height: 60px;
  pointer-events: none; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear; } }

.App-header {
  /* background-color: #282c34; */
  background-color: 'transparent';
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

* {
  box-sizing: border-box; }

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 33.33%;
  padding: 10px; }

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.submit-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #0A043B;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase; }

.submit-button:hover {
  background: #2674c8; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap !important;
  font-size: 14px !important;
  margin-top: 15px; }

.fa-plus-circle {
  font-size: 22px;
  color: #0070c0; }

.fa-trash-o {
  font-size: 22px;
  color: red; }

.error-msg {
  font-size: 12px; }

.outer-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px; }

.inner-block {
  background-color: #fff;
  width: 70%;
  border: 1px;
  margin-top: 3.5%; }

.custom_modal {
  position: fixed;
  top: 15%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  overflow: scroll; }

.custom_modal .modal_body {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  /* background: #fff; */
  max-width: 935px;
  margin: auto;
  border-radius: 5px;
  max-height: 600px;
  padding: 26px 22px 22px 22px;
  box-shadow: 0 0px 9px #000;
  background: #fff;
  overflow-y: scroll;
  margin-top: 10px !important; }

.custom_modal.fade {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out,-o-transform .3s ease-out; }

.custom_modal .modal_title {
  margin-top: 0px;
  font-size: 18px;
  font-size: 18px;
  color: #0c3850; }

.MuiTypography-h5 {
  font-size: 20px !important;
  text-align: center !important; }

.MuiTypography-subtitle2 {
  font-size: 17px !important; }

.MuiFormLabel-root {
  font-size: 16px !important; }

.MuiSelect-select.MuiSelect-select {
  text-align: left; }

.MuiInputBase-input {
  font-size: 16px !important; }

.MuiSelect-root {
  font-size: 16px !important; }

.MuiTypography-body1 {
  font-size: 16px !important; }

.MuiButton-label {
  font-size: 16px !important; }

.form-attribute {
  color: #122539;
  font-size: 14px;
  font-weight: bold;
  padding-top: 5px;
  text-transform: capitalize; }

.form-input {
  color: #0c3850;
  font-size: 14px;
  padding: 5px;
  border: 1px groove grey;
  border-radius: 5px; }

.disableLabel {
  pointer-events: none;
  opacity: 0.5; }

.pagetitle {
  color: #0A043B;
  font-weight: 700;
  letter-spacing: 1px; }

.not-active {
  pointer-events: none;
  cursor: default;
  opacity: 0;
  margin-left: -20%; }

.MuiButtonBase-root.Mui-disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.5; }

/* Custom Styles added */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: #369241; }

input:focus + .slider {
  box-shadow: 0 0 1px #369241; }

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px); }

/* Rounded sliders */
.slider.round {
  border-radius: 10px; }

.slider.round:before {
  border-radius: 10px; }

/*accordion*/
.accordion {
  font-weight: 700;
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  margin-bottom: 5px; }

.active, .accordion:hover {
  background-color: #ccc; }

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  margin-bottom: -20px;
  padding-bottom: 30px; }

.list-table th {
  text-align: center !important;
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  font-weight: 400;
  text-align: center;
  border: 1px solid #ddd; }

.list-table td {
  border: 1px solid #ddd;
  text-align: center; }

.container-fluid {
  padding: 10px 20px; }

.text-center {
  text-align: center; }

.center-block1 {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.formdiv-default {
  border-color: #ddd; }

.formdiv {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px; }

.formdiv-default .formdiv-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd; }

.iconRedColor {
  color: red; }

.iconGreenColor {
  color: green; }

.gray_icon {
  color: gray; }

.MuiTypography-subtitle2 {
  text-align: justify; }

.MuiTableCell-paddingCheckbox:last-child {
  color: black !important; }

.field-keyword-table {
  text-align: left;
  border: 1px solid #cce6ff;
  width: 80%; }

.field-keyword-table th, .field-keyword-table td {
  border: 1px solid #cce6ff;
  padding: 5px;
  text-transform: none !important; }

.mobile-icon {
  font-size: 100px !important;
  font-weight: bold;
  border-radius: 50%;
  color: #0A043B;
  margin-top: -30px;
  margin-bottom: -30px; }

.fa-clipboard {
  color: #0A043B;
  cursor: pointer; }

.MuiIconButton-colorInherit {
  background-color: transparent !important; }

.style-grid-input {
  opacity: 0.8; }

.MuiFormControl-root {
  text-align: left !important; }

.MuiFormHelperText-root {
  font-size: 1rem !important;
  text-transform: none !important;
  color: darkgrey !important; }

@media (max-width: 768px) {
  .mobile_responsive {
    width: 90% !important; }
  .sm_device {
    text-align: center !important; }
  .main-content {
    margin-top: 10px !important; }
  .style-sm {
    font-size: 10px !important; }
  .sm-plot {
    width: 100% !important;
    overflow-x: auto !important; } }

.admin-dashboard-outer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vw !important;
  background-color: #ffffff;
  flex-direction: column;
  margin-bottom: 20px; }

.admin-dashboard-outer-div {
  display: flex;
  width: 85%;
  height: 100%;
  flex-direction: column;
  border: 2px solid lightgray;
  margin-left: 6%; }

.admin-dashboad-total-div {
  display: flex;
  width: 100%;
  height: 20%; }

.total-title-div {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #285C60; }

.total-value-outer-div {
  width: 80%;
  display: flex;
  align-items: center;
  justify-self: center; }

.total-value-inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 80%;
  flex-direction: column; }

.total-value-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px; }
  .total-value-div p {
    color: #000000; }

.total-value-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px !important;
  height: 30px !important;
  min-width: 50px !important;
  min-height: 30px !important;
  max-width: 50px !important;
  max-height: 30px !important;
  border: 2px solid #0A043B;
  color: #000000; }

.total-title-header-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 35%;
  border-radius: 4px; }
  .total-title-header-div p {
    color: #ffffff;
    margin: 0;
    font-size: 20px;
    font-weight: 500; }

.seprator-line {
  width: 100%;
  border: 1px solid lightgray; }

.admin-dasboard-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 15px;
  padding-left: 15%;
  padding-right: 5%; }
  .admin-dasboard-icon i {
    padding-right: 10px;
    font-size: 20px;
    margin-top: -11px; }
  .admin-dasboard-icon h4 {
    font-size: 20px;
    font-weight: 500;
    color: #0A043B;
    margin-top: 20px;
    margin-left: -5%; }

.total-div-background-color {
  background-color: #4AAFB2 !important; }

.total-value-margin {
  margin-top: -5px; }

.employer-dashboard-outer-div {
  justify-content: flex-start; }

.employer-dashboad-total-div {
  height: 50%; }

.employer-dashboard-outer-wrapper {
  height: 35vw !important; }

.employer-total-value-outer-div {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 15px; }

.employer-total-inner-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%; }
  .employer-total-inner-div p {
    color: #000000; }

.employer-total-value {
  margin-left: 20px;
  width: 70%; }

.employer-total-inner-header {
  width: 30%; }

.admin-tab-icon {
  padding-left: 11% !important; }

.admin-employer-select-div .css-b62m3t-container {
  min-width: 250px; }

.admin-employer-dashboard {
  width: 100%;
  height: 80%; }

.admin-employer-dashboard-outer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%; }

.admin-employer-dashboard-outer-div {
  border: none;
  width: 90%; }

.admin-employer-dashboard-wrapper {
  height: 90vw !important; }

.material-component-spinner-div {
  position: absolute; }
  .material-component-spinner-div .material-spinner-loader {
    font-size: 6px !important;
    top: -16px;
    margin-right: 10px; }

.material-component-outer .MuiToolbar-regular {
  padding-right: 5% !important; }

.brand-color {
  color: #0A043B !important; }

.employer-partner-dashboard th, .employer-partner-dashboard td {
  text-align: center; }

.admin-dashboard-filter-dropdown-outer-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px 10px 10px;
  position: absolute;
  margin: -5em;
  margin-left: 30%; }
  @media (min-width: 1400px) {
    .admin-dashboard-filter-dropdown-outer-div {
      padding: 14px 10px 10px 10px !important; } }
  .admin-dashboard-filter-dropdown-outer-div .form-wrap {
    width: 200px;
    padding-right: 15px; }
  .admin-dashboard-filter-dropdown-outer-div i {
    font-size: 25px !important;
    padding-right: 10px; }
  .admin-dashboard-filter-dropdown-outer-div .css-13cymwt-control {
    height: 43px !important; }

.material-component-outer .MTableHeader-header-13 {
  z-index: 0 !important; }

.admin-dashboard-filter-dropdown-finance-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px 10px 10px;
  position: absolute;
  margin: -5em;
  margin-left: 31%; }
  .admin-dashboard-filter-dropdown-finance-div .form-wrap {
    width: 200px;
    padding-right: 15px; }
  .admin-dashboard-filter-dropdown-finance-div i {
    font-size: 25px !important;
    padding-right: 10px; }
  .admin-dashboard-filter-dropdown-finance-div .css-13cymwt-control {
    height: 43px !important; }

.history-popup-modal-inner-div {
  flex-direction: column; }
  .history-popup-modal-inner-div h4 {
    color: black !important;
    padding-bottom: 15px !important; }

.margin-left-15 {
  margin-left: 15px; }

.default-grey-color {
  color: #808080 !important; }

.month-div-dropdown {
  z-index: 99; }

.ytd-box-outer-div {
  display: flex; }

.margin-right-7 {
  margin-right: 7px; }

.monthly-ytd-label-div {
  display: flex;
  justify-content: space-around;
  margin-left: 22vw; }
  @media (min-width: 1300px) {
    .monthly-ytd-label-div {
      margin-left: 23.5vw; } }

.second-span-monthly-ytd {
  padding-left: 1vw; }

.third-span-monthly-ytd {
  margin-right: -4vw; }

.dashboard-facility-management-p {
  font-size: 19px !important; }

.height-100-fix {
  height: 100px !important; }
  .height-100-fix .css-13cymwt-control {
    height: 45px !important; }
