.breadcrumb1 li.selected {
    cursor: pointer;
    text-decoration: underline;
    color: #419498;
}

.breadcrumb1 li:hover {
    cursor: pointer;
}

.bradcrumb-sides {
    display: flex;
    justify-content: space-between;
}

.left-side {
    align-self: flex-start;
}

.right-side {
    align-self: flex-end;
}

.tick-icon-green {
    color: green;
    cursor: pointer;
}

.tick-icon-gray {
    color: gray;
    cursor: pointer;
}

.tab-container {
    display: flex;
}

.tab-button {
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #0A043B;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.25s;
}

.tab-button:hover {
    background-color: #2674c8;
    color: white;
}

.tab-button.selected {
    background-color: #0A043B;
    color: #fff;
    border-top: 2px solid #0A043B;
    border-left: 2px solid #0A043B;
    border-right: 2px solid #0A043B;
    border-bottom: none;
}

.import-button {
    margin-left: auto;
}

.margin-import-loader {
    margin-left: 6%;
}

.underline-link {
    text-decoration: underline;
}


.sample-file-width {
    width: 13% !important;
    margin-right: 2%;
}

.tick-icon-red {
    color: red;
    cursor: pointer;
}

.dropped-out-opacity-icon {
    opacity: 0.5;
}

.dropped-out-opacity-text {
    opacity: 0.5;
}

.icon-alignment {
    margin-left: 20px;
}

.previous-value {
    color: #ff0000;
}

.update-value {
    color: #008000;
}

.no-value {
    color: #808080;
}

.compare-candidate-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute !important;
    flex-direction: column;
    width: 93vw;
    left: 6%;
    top: 0;
    right: auto;
    bottom: auto;
    background-color: white;
    opacity: 1;
    top: 17%;
    min-height: 30%;
    height: auto;
    max-height: 75%;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
    -webkit-box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
    -moz-box-shadow: 1px 4px 29px -4px rgba(18, 17, 17, 0.82);
}

@media(max-width: 768px) {
    .compare-candidate-modal {
        left: 5vw;
        width: 90vw;
    }
}

@media(max-width: 350px) {
    .compare-candidate-modal {
        top: 5%;
        height: 500px;
        overflow-y: scroll;
    }
}

.compare-modal-main-row-header {
    background-color: #ADDADB;
}

.compare-modal-main-row-header th {
    color: #000000;
    font-weight: 600;
    padding: 10px;
    background-color: #f2f2f2;
    border: 2px solid black;
}

.candidate-model-scroll {
    overflow-y: auto;
    overflow-x: auto;
}

.candidate-model-scroll table {
    width: 100%;
    border-collapse: collapse;
}

.candidate-model-scroll th,
.candidate-model-scroll td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
}

.candidate-model-scroll th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.candidate-model-scroll tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.candidate-model-scroll tbody tr:hover {
    background-color: #ddd;
}

.import-candidate-heading {
    color: #419498;
    margin-bottom: 10px;
    font-weight: 700;
    text-align: center;
}

.new-row-value {
    background-color: forestgreen;
    color: #f9f9f9;
}

.tick-icon-orange {
    color: #FFA500;
    cursor: pointer;
}

.material-table-export-audit-outer-div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.material-table-export-audit-outer-div .material-table-export-audit {
    font-size: 18px;
    border: none;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    margin-right: 10px;
}

.tick-icon-yellow {
    color: #FFD700;
    cursor: pointer;
}

.form-navigation-buttons {
    display: flex;
    justify-content: space-between;
}

.form-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.download-link-button {
    background-color: #0A043B;
    color: white;
    padding: 4px 5px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
}

.file-download-widget {
    margin: 8px 0;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}